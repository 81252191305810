import { AgilityApp } from '@services/agilityApps/constants'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { GetContextMenuItemsParams, ProcessCellForExportParams } from 'ag-grid-community'
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'

export const timeFormatter = (param: ValueFormatterParams<AgilityApp>) => {
  return timestampToDate(param.value)
}

export const jsonFormatter = (param: ValueFormatterParams<AgilityApp>) => {
  return param.value ? JSON.stringify(param.value) : param.value
}

const processCellForClipboard = (params: ProcessCellForExportParams<AgilityApp>) => {
  const colDef = params.column.getColDef()
  if (colDef.valueFormatter && typeof colDef.valueFormatter === 'function') {
    return colDef.valueFormatter({
      ...params,
      data: params.node?.data,
      node: params.node || null,
      colDef: colDef,
    } as ValueFormatterParams<AgilityApp, any>)
  } else {
    return params.value
  }
}

export const columnDefs: ColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    pinned: 'left',
    checkboxSelection: true,
    minWidth: 200,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  { field: 'createdAt', headerName: 'Created At', valueFormatter: timeFormatter },
  {
    field: 'urlSuffix',
    headerName: 'URL Suffix',
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'crawlerConfig',
    headerName: 'Crawler Config',
    valueFormatter: jsonFormatter,
  },
  {
    field: 'iframeUrl',
    headerName: 'iframe URL',
  },
  {
    field: 'chatTitle',
    headerName: 'Chat Title',
  },
  {
    field: 'chatSubtitle',
    headerName: 'Chat Subtitle',
  },
  {
    field: 'suggestedQuestions',
    headerName: 'Suggested Questions',
    cellRenderer: (params: ICellRendererParams<AgilityApp>) => {
      if (Array.isArray(params.value)) {
        return params.value.join(', ') || '-'
      }
      return '-'
    },
  },
  {
    field: 'scrapedUrls',
    headerName: 'Scraped URLs',
    valueFormatter: jsonFormatter,
  },
  {
    field: 'numScrapedUrls',
    headerName: 'Number of Scraped URLs',
  },
]

export const agilityGridOptions: GridOptions = {
  columnDefs,
  rowSelection: 'single',
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
    flex: 1,
    width: 100,
    minWidth: 100,
  },
  animateRows: true,
  getContextMenuItems: (params: GetContextMenuItemsParams) => {
    if (params.value === undefined) {
      return []
    } else {
      return ['copy']
    }
  },
  processCellForClipboard,
}
