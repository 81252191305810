import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { cleanlabTransformResponse } from '@services/common'
import {
  DatasheetColumnsRes,
  ErrorCountData,
  GridLayoutRes,
  queryKeys,
  RowCountType,
  TopImageExamples,
} from '@services/datasheet/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { isUUID4 } from '@utils/functions/isUUID4'
import { GridApi } from 'ag-grid-community'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'
import { ColumnStatus } from 'src/pages/cleanset/Cleanset.types'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasheets`,
  withCredentials: true,
  transformResponse: cleanlabTransformResponse,
})

const axiosClientV1 = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasheets/v1`,
  withCredentials: true,
  transformResponse: cleanlabTransformResponse,
})

export const useRowCount = (
  cleansetId: string,
  filterType: RowCountType,
  gridApi: GridApi | null,
  enabled: boolean,
  isInitialFetch?: boolean
) => {
  const { data } = useQuery({
    queryKey: [
      ...queryKeys.datasheet.id(cleansetId).rowCount().filterType(filterType),
      JSON.stringify(gridApi?.getFilterModel()),
    ],
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const body = { filter: gridApi?.getFilterModel() || null }
      const client = checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') ? axiosClientV1 : axiosClient
      const res = await client.post<{ num_rows: number; column_status: string }>(
        `/${cleansetId}/_compute?type=count&filter=${filterType}`,
        body,
        createAuthHeaders(accessToken)
      )
      return {
        columnStatus: checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS')
          ? res.data.column_status
          : ColumnStatus.READY,
        numRows: res.data.num_rows,
      }
    },
    enabled: (isUUID4(cleansetId) && !!gridApi && enabled) || isInitialFetch,
    refetchOnWindowFocus: false,
    meta: { error: `Failed to fetch ${filterType} row count.` },
  })
  // Return -1 if value has not been fetched or does not exist on the backend
  return data ?? { columnStatus: ColumnStatus.PENDING, numRows: -1 }
}

export const useIssueColumns = (cleansetId: string, enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.datasheet.id(cleansetId).issueColumns(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const client = checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') ? axiosClientV1 : axiosClient
      const res = await client.get<{ issue_columns: string[] }>(
        `/${cleansetId}/issue_column_names`,
        createAuthHeaders(accessToken)
      )
      return res.data.issue_columns
    },
    enabled: isUUID4(cleansetId) && enabled,
    refetchOnWindowFocus: false,
    meta: { error: 'Failed to fetch issue columns.' },
  })
  // Return [] if value has not been fetched or does not exist on the backend
  return { data: data ?? [], isLoading: isLoading }
}

export const useColumnValues = (
  cleansetId: string,
  columnName: string,
  enabled: boolean,
  queryOnce?: boolean
) => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.datasheet.id(cleansetId).columnValues().column(columnName),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get<{ unique_column_values: string[] }>(
        `/unique-column-values/${cleansetId}/${encodeURIComponent(columnName)}`,
        createAuthHeaders(accessToken)
      )
      return res.data.unique_column_values
    },
    enabled: isUUID4(cleansetId) && enabled,
    staleTime: queryOnce ? Infinity : undefined,
    meta: { error: 'Failed to fetch column values.' },
  })
  // Return [] if value has not been fetched or does not exist on the backend
  return {
    data: (data ?? []).map((e) => e ?? '-').sort((a, b) => `${a}`.localeCompare(`${b}`)),
    isLoading: isLoading,
  }
}

export const useLabelOptions = (
  givenLabels: string[],
  cleansetId: string,
  isMultiClass: boolean
): string[] => {
  // Helper function to return the valid labeling options, which is a set union of the given labels and the clean labels
  // We only fetch the clean label set for multiclass, because this is the only task type that supports adding labels
  const cleanLabelColumn = 'clean_label'
  const { data: cleanLabelValues } = useColumnValues(
    cleansetId,
    cleanLabelColumn,
    isMultiClass,
    true
  )
  const cleanLabelOptions: string[] = cleanLabelValues.map(String)
  const labelOptions = [...new Set([...givenLabels, ...cleanLabelOptions])].filter(
    (label) => label !== '-'
  )
  return labelOptions
}

export const useMetadataColumns = (cleansetId: string, enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.datasheet.id(cleansetId).metadataColumns(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const client = checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') ? axiosClientV1 : axiosClient
      const res = await client.get<{ metadata_columns: string[] }>(
        `/${cleansetId}/metadata_column_names`,
        createAuthHeaders(accessToken)
      )
      return res.data.metadata_columns
    },
    enabled: isUUID4(cleansetId) && enabled,
    refetchOnWindowFocus: false,
    meta: { error: 'Failed to fetch metadata columns.' },
  })
  // Return [] if value has not been fetched or does not exist on the backend
  return { data: data ?? [], isLoading: isLoading }
}

export const useTopImageExamples = ({
  cleansetId,
  label,
  enabled = true,
}: {
  cleansetId: string
  label: string
  enabled?: boolean
}): UseQueryResult<TopImageExamples> => {
  return useQuery({
    queryKey: queryKeys.datasheet.id(cleansetId).topImageExamples().label(label),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const url = `/${cleansetId}/_compute?type=top_images&label=${label}`
      const response = await axiosClient.get(url, createAuthHeaders(accessToken))
      return response.data
    },
    enabled,
  })
}

export const useCheckHighlyUnlabeledClass = ({
  cleansetId,
  onSuccess,
}: {
  cleansetId: string
  onSuccess?: VoidFunction
}) => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.datasheet.id(cleansetId).checkHighlyUnlabeledClass(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const url = `/check_highly_unlabeled_class/${cleansetId}`
      const response = await axiosClient.get(url, createAuthHeaders(accessToken))
      return response.data
    },
    onSuccess: () => {
      onSuccess?.()
    },
  })
  return data
    ? {
        showDataLabelingWorkflow: data.show_data_labeling_workflow,
        hasHighlyUnlabeledClass: data.has_highly_unlabeled_class,
        lastRowAboveThreshold: data.last_row_above_threshold,
        unlabeledSuggestedLabelConfidenceThreshold:
          data.unlabeled_suggested_label_confidence_threshold,
        isLoading: isLoading,
      }
    : { isLoading: isLoading }
}

export const useErrorsByColumn = ({
  cleansetId,
  column,
  enabled,
}: {
  cleansetId: string
  column: string
  enabled: boolean
}): ErrorCountData => {
  const { data } = useQuery({
    queryKey: queryKeys.datasheet.id(cleansetId).errorsByColumn().column(column),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const url = `/${cleansetId}/_error_by_values/${column}`
      const response = await axiosClient.get(url, createAuthHeaders(accessToken))
      return response.data
    },
    enabled: enabled,
  })
  return camelCaseKeys(data) as ErrorCountData
}

export const useColumns = (cleansetId: string) => {
  const { data, isLoading } = useQuery<DatasheetColumnsRes>({
    queryKey: queryKeys.datasheet.id(cleansetId).columns(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const url = `/columns/${cleansetId}`
      const response = await axiosClientV1.get(url, createAuthHeaders(accessToken))
      return response.data
    },
    enabled: isUUID4(cleansetId),
    meta: { error: 'Failed to fetch column info.' },
    refetchInterval: (data?: DatasheetColumnsRes) => {
      const columnStatuses = data?.datasheet_column_statuses
      const hasPendingData = Object.values(columnStatuses ?? {}).includes(ColumnStatus.PENDING)
      if (hasPendingData) {
        return 10000
      }
      return false
    },
  })
  return {
    datasheetColumns: data?.datasheet_columns ?? [],
    datasheetColumnStatuses: data?.datasheet_column_statuses ?? {},
    datasetColumns: data?.dataset_columns ?? [],
    isLoading: isLoading,
  }
}

// Hook is not used at the moment, placeholder for future use
export const useGridLayout = (cleansetId: string) => {
  const { data } = useQuery<GridLayoutRes>({
    queryKey: queryKeys.datasheet.id(cleansetId).gridLayout(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const url = `/${cleansetId}/grid_layout`
      const response = await axiosClient.get(url, createAuthHeaders(accessToken))
      return response.data
    },
    enabled: isUUID4(cleansetId),
    meta: { error: 'Failed to fetch previous grid layout.' },
  })
  return data ?? { columnState: null, filterModel: null }
}
