import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useEventTracking } from '@hooks/useEventTracking'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys as cleansetQueryKeys } from '@services/cleanset/constants'
import {
  ProjectRowRes,
  queryKeys as projectQueryKeys,
  queryKeys,
} from '@services/project/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'

import { CreateProjectReq } from '../../pages/projectForm/ProjectForm.types'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/projects`,
  withCredentials: true,
})

export const useCreateProjectMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: AxiosResponse) => void
  onError?: (err: AxiosError) => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { trackEvent } = useEventTracking()
  return useMutation({
    mutationFn: async (req: CreateProjectReq) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        name: req.projectName,
        dataset_id: req.datasetId,
        modality: req.modality,
        tasktype: req.tasktype,
        label_column: req.labelColumn,
        feature_columns: req.featureColumns,
        text_column: req.textColumn,
        model_type: req.modelType ?? '',
        pred_probs_filename: req.predProbsFilename ?? '',
      }
      return await axiosClient.post('/clean', body, createAuthHeaders(accessToken))
    },
    onSuccess: (res, variables) => {
      void trackEvent(MixpanelEvents.createProjectComplete, {
        datasetId: variables.datasetId,
        modality: variables.modality,
        modelType: variables.modelType,
        projectName: variables.projectName,
        tasktype: variables.tasktype,
      })
      void queryClient.invalidateQueries(projectQueryKeys.projects.all())
      onSuccess?.(res)
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
      onError?.(err as AxiosError)
    },
  })
}

export const useUpdateProjectMutation = (refreshData: () => void) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (data: ProjectRowRes) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(`/${data.id}`, data, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(projectQueryKeys.projects.all())
      void queryClient.invalidateQueries(cleansetQueryKeys.cleanset.all())
      refreshData()
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to edit Project' })
    },
  })
}

export const useUpdateProjectNameMutation = (refreshData: () => void) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (data: { id: string; name: string }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(`/${data.id}`, data, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(projectQueryKeys.projects.all())
      void queryClient.invalidateQueries(cleansetQueryKeys.cleanset.all())
      refreshData()
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to update Project name' })
    },
  })
}

export const useDeleteProjectMutation = (refreshData: () => void) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (projectId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.delete(`/${projectId}`, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(projectQueryKeys.projects.all())
      refreshData()
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Deleted Project',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to delete Project' })
    },
  })
}

export const useSetProjectTemplateStatusMutation = (projectId: string) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (templateStatus: boolean) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(
        `/manage_template/${projectId}/${templateStatus}`,
        { project_id: projectId, template_status: templateStatus },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(projectQueryKeys.projects.id(projectId).templateStatus())
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Project template status updated successfully',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, {
        title: 'Failed to update Project template status',
      })
    },
  })
}

type CopyMutationOptions = { userId: string; projectId: string }
export const useCopyProjectMutation = ({
  useMutationOptions,
}: {
  useMutationOptions?: UseMutationOptions<AxiosResponse<any, any>, unknown, CopyMutationOptions>
} = {}) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async ({ userId, projectId }: CopyMutationOptions) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.post(
        '/copy_project',
        { project_id: projectId, destination_user_id: userId },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Project copied successfully',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to copy Project' })
    },
    ...useMutationOptions,
  })
}

type CopyTemplateProjectMutationOptions = {
  userId: string
  projectId: string
  projectName?: string
}
export const useCopyTemplateProjectMutation = ({
  useMutationOptions,
}: {
  useMutationOptions?: UseMutationOptions<
    ProjectRowRes,
    AxiosError,
    CopyTemplateProjectMutationOptions
  >
} = {}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ userId, projectId, projectName }: CopyTemplateProjectMutationOptions) => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.post(
        '/copy_template_project',
        {
          project_id: projectId,
          destination_user_id: userId,
          ...(projectName ? { name_to_copy: projectName } : {}),
        },
        createAuthHeaders(accessToken)
      )
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projects.all())
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Template Project copied successfully',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err, { title: 'Failed to copy template Project' })
    },
    ...useMutationOptions,
  })
}
