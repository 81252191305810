import { Center, Tooltip } from '@chakra-ui/react'
import TextOverflowTooltip from '@common/textOverflowTooltip/TextOverflowTooltip'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { matchFieldName } from '@utils/functions/matchFieldName'
import { ColDef, GridApi, GridOptions, ValueFormatterParams } from 'ag-grid-community'
import { ColumnApi } from 'ag-grid-community/dist/lib/columns/columnApi'
import { FiCheck, FiX } from 'react-icons/fi'

import { ColumnStatus } from '../Cleanset.types'
import { ActionType } from '../commandpalette/CommandPalette.types'
import { getHeaderName } from '../filterMenu/FilterMenu.helpers'
import {
  CleansetFilterActions,
  FilterActionType,
  FilterData,
  FilterOperator,
} from '../filterReducer/FilterState.types'
import { EnabledCleanlabColumnConfigs } from './columnConfigs/ColumnConfigs'
import { CLEANLAB_FRONTEND_COLUMN, NUM_ROWS_PER_PAGE, unsortableColumns } from './Datasheet.types'

export const checkNullValueInFilter = (
  filterType: string,
  input: string | number | boolean | string[]
) => {
  const isNull = input === '-'
  return isNull && filterType === FilterOperator.NotEquals
    ? 'notBlank'
    : isNull
      ? 'blank'
      : filterType
}

export const goToPageAndSelectRow = (
  rowIndex: number,
  gridApi: GridApi,
  onPageChange: VoidFunction,
  retries = 1
) => {
  if (rowIndex > 0) {
    const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex)
    // If row has not rendered yet, wait 300ms and try again until retries expire
    if (!rowNode?.data && retries > 0) {
      setTimeout(() => goToPageAndSelectRow(rowIndex, gridApi, onPageChange, retries - 1), 300)
    }
    const pageNum = Math.floor(rowIndex / NUM_ROWS_PER_PAGE)

    gridApi.paginationGoToPage(pageNum)
    rowIndex > -1 && gridApi.ensureIndexVisible(rowIndex, 'middle')
    rowNode?.setSelected(true)
  }
  onPageChange()
}

export const getDisplayedColumnsForExport = (columnApi: ColumnApi) => {
  const allDisplayedColumns = columnApi.getAllDisplayedColumns()
  if (!allDisplayedColumns || allDisplayedColumns.length === 0) return []
  const displayedColumns = allDisplayedColumns.map((c) => c.getColId())
  const invalidColumns: string[] = ['_cleanlab_media_url']
  return displayedColumns.filter((c) => !invalidColumns.includes(c))
}

// Backend uses different column names, so handle conversion here
export const getBackendColName = (feCol: string) => {
  if (Object.keys(EnabledCleanlabColumnConfigs).includes(feCol)) {
    const config = EnabledCleanlabColumnConfigs[feCol]
    return config.backendColumnName === undefined
      ? feCol.replace('_cleanlab_', '')
      : config.backendColumnName
  }
  return feCol
}

// Get cell component of Auto label column
export const getAutoLabelCellComponent = (
  params: ValueFormatterParams,
  thresholdValueScore: number | null,
  unlabeledSuggestedLabelConfidenceThreshold: number,
  textColor: string,
  borderColor: string,
  thresholdRowIndex: number
) => {
  if (params.data._cleanlab_clean_label || params.data._cleanlab_action === ActionType.EXCLUDE) {
    return (
      <Center
        bg="transparent"
        height="20px"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="4px"
        px="4px"
        fontSize="xs"
        color={textColor}
        fontWeight="500"
      >
        User labeled
      </Center>
    )
  } else if (params.data._cleanlab_suggested_label_confidence_score === thresholdValueScore) {
    if ((params.node?.rowIndex ?? 0) < thresholdRowIndex) {
      return (
        <Tooltip label="This data point is selected for auto labeling">
          <span>
            <FiCheck size="16px" />
          </span>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip label="This datapoint is below the auto labeling threshold and will not be auto labeled">
          <span>
            <FiX size="16px" />
          </span>
        </Tooltip>
      )
    }
  } else if (
    params.data._cleanlab_suggested_label_confidence_score >
    (thresholdValueScore ?? unlabeledSuggestedLabelConfidenceThreshold)
  ) {
    return (
      <Tooltip label="This data point is selected for auto labeling">
        <span>
          <FiCheck size="16px" />
        </span>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip label="This datapoint is below the auto labeling threshold and will not be auto labeled">
        <span>
          <FiX size="16px" />
        </span>
      </Tooltip>
    )
  }
}

let xOffsetLeft = 0
let xOffsetRight = 0
let yOffset = 0

// Scroll left pinned headers with data
const onScrollLeftPinned = (evt: any, leftHeaderClass: string) => {
  document.getElementsByClassName(leftHeaderClass)[0].scrollTo(evt.srcElement.scrollLeft, 0)
  xOffsetLeft = evt.srcElement.scrollLeft
}

// Scroll right pinned headers with data
const onScrollRightPinned = (evt: any, rightHeaderClass: string) => {
  document.getElementsByClassName(rightHeaderClass)[0].scrollTo(evt.srcElement.scrollLeft, 0)
  xOffsetRight = evt.srcElement.scrollLeft
}

// Scroll left and right pinned columns vertically via ag-body-viewport scroll
const onScrollVertical = (evt: any) => {
  document
    .getElementsByClassName('left-pinned-container')[0]
    .scrollTo(
      xOffsetLeft,
      evt.srcElement.scrollTop + (evt.srcElement.scrollTop > yOffset ? 27 : -27)
    )
  document
    .getElementsByClassName('right-pinned-container')[0]
    .scrollTo(
      xOffsetRight,
      evt.srcElement.scrollTop + (evt.srcElement.scrollTop > yOffset ? 27 : -27)
    )
  yOffset = evt.srcElement.scrollTop
}

// Returns 0 if macOS is hiding scroll bars
// Returns 15 if not
export const getScrollbarWidth = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  document.body.appendChild(outer)

  const widthNoScroll = outer.offsetWidth
  // force scrollbars
  outer.style.overflow = 'scroll'

  // add innerdiv
  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  const widthWithScroll = inner.offsetWidth

  // remove divs
  outer.parentNode?.removeChild(outer)

  return widthNoScroll - widthWithScroll
}

// Splits AG-Grid into left and right pinned scrollable sections
export const generateSplitGrid = () => {
  const isFirefox = navigator.userAgent.includes('Firefox')
  const showScrollbars = getScrollbarWidth()

  //wrap the pinned left header area
  const leftHeaderParent = document.getElementsByClassName('ag-header')[0]
  const leftHeaderChild = document.getElementsByClassName('ag-pinned-left-header')[0]
  const newLeftHeaderContainer = document.createElement('div')
  const leftHeaderClass = isFirefox
    ? showScrollbars
      ? 'left-header-container-firefox'
      : 'left-header-container-firefox-no-scroll'
    : showScrollbars
      ? 'left-header-container'
      : 'left-header-container-firefox-no-scroll'

  newLeftHeaderContainer.id = leftHeaderClass
  newLeftHeaderContainer.className = leftHeaderClass

  // set the newLeftHeaderContainer as child (instead of the element)
  leftHeaderParent.replaceChild(newLeftHeaderContainer, leftHeaderChild)

  // set new element as child of newLeftHeaderContainer
  newLeftHeaderContainer.appendChild(leftHeaderChild)

  // wrap the pinned left column area
  const leftDataParent = document.querySelector<HTMLElement>('.ag-body-viewport')!

  const leftPinnedChild = document.getElementsByClassName('ag-pinned-left-cols-container')[0]
  const newLeftPinnedContainer = document.createElement('div')
  newLeftPinnedContainer.id = 'left-pinned-container'
  newLeftPinnedContainer.className = 'left-pinned-container'

  // set the newDivContainer as child (instead of the element)
  leftDataParent.replaceChild(newLeftPinnedContainer, leftPinnedChild)

  // set element as child of newLeftDataContainer
  newLeftPinnedContainer.appendChild(leftPinnedChild)

  //wrap the pinned right header area
  const rightHeaderParent = document.getElementsByClassName('ag-header')[0]
  const rightHeaderChild = document.getElementsByClassName('ag-pinned-right-header')[0]
  const newRightHeaderContainer = document.createElement('div')

  const rightHeaderClass = showScrollbars
    ? isFirefox
      ? 'right-header-container-firefox'
      : 'right-header-container'
    : 'right-header-container-no-scroll'

  newRightHeaderContainer.id = rightHeaderClass
  newRightHeaderContainer.className = rightHeaderClass

  // set the newDivContainer as child (instead of the element)
  rightHeaderParent.replaceChild(newRightHeaderContainer, rightHeaderChild)

  // set element as child of newDivContainer
  newRightHeaderContainer.appendChild(rightHeaderChild)

  //wrap the pinned right Data area
  const rightDataParent = document.getElementsByClassName('ag-body-viewport')[0]
  const rightDataChild = document.getElementsByClassName('ag-pinned-right-cols-container')[0]
  const newRightPinnedContainer = document.createElement('div')
  newRightPinnedContainer.id = 'right-pinned-container'
  newRightPinnedContainer.className = 'right-pinned-container'

  // set the newDivContainer as child (instead of the element)
  rightDataParent.replaceChild(newRightPinnedContainer, rightDataChild)
  // set element as child of newDivContainer
  newRightPinnedContainer.appendChild(rightDataChild)

  // Horizontal scroll of headers and columns
  document
    .getElementsByClassName('left-pinned-container')[0]
    .addEventListener('scroll', (evt) => onScrollLeftPinned(evt, leftHeaderClass), {
      passive: true,
    })
  document
    .getElementsByClassName('right-pinned-container')[0]
    .addEventListener('scroll', (evt) => onScrollRightPinned(evt, rightHeaderClass), {
      passive: true,
    })

  // Detect when ag-body-viewport scrolls, then scroll pinned columns vertically
  document
    .getElementsByClassName('ag-body-viewport')[0]
    .addEventListener('scroll', onScrollVertical, { passive: true })
}

export const createAutoLabelColumn = (
  newThresholdValueScore: number,
  newThresholdRowIndex: number,
  gridApi: GridApi | null,
  gridOptions: GridOptions,
  thresholdBorderColor: string,
  setGridOptions: (gridOptions: GridOptions) => void,
  modality: string,
  unlabeledSuggestedLabelConfidenceThreshold: number,
  userLabeledTagColor: string,
  userLabeledTagBorderColor: string
) => {
  gridOptions.getRowStyle = (params) => {
    if (params.node.rowIndex === (newThresholdRowIndex ?? 0) - 1) {
      return {
        borderBottom: `2px solid ${thresholdBorderColor}`,
      }
    }
  }

  if (gridApi && gridOptions.columnDefs) {
    // Update Auto label column
    const autoLabelColumnDef = {
      field: 'auto-label',
      headerComponent: TextOverflowTooltip,
      headerClass: 'added-column',
      headerComponentParams: {
        text: 'Auto label',
      },
      tooltipField: 'Auto label',
      sortable: false,
      minWidth: 100,
      pinned: modality === 'tabular' ? 'right' : undefined,
      cellRenderer: (params: ValueFormatterParams) => {
        return getAutoLabelCellComponent(
          params,
          newThresholdValueScore,
          unlabeledSuggestedLabelConfidenceThreshold,
          userLabeledTagColor,
          userLabeledTagBorderColor,
          newThresholdRowIndex ?? 0
        )
      },
    } as ColDef

    // Remove old auto-label column if it exists
    gridOptions.columnDefs = gridOptions.columnDefs.filter((e: ColDef) => e.field !== 'auto-label')

    // Find index of confidence score column so we can insert
    // Auto label column after it
    const confidenceScoreIndex = gridOptions.columnDefs.findIndex(
      (e: ColDef) => e.field === '_cleanlab_suggested_label_confidence_score'
    )

    // Insert auto-label column after confidence score column
    gridOptions.columnDefs.splice(confidenceScoreIndex + 1, 0, autoLabelColumnDef)

    // Disable sorting for all columns and hide Label Issue Score column
    gridOptions.columnDefs.forEach((e: ColDef) => {
      e.sortable = false
      if (e.headerComponentParams) {
        if (e.headerComponentParams && e.headerComponentParams.disableSorting === undefined) {
          e.headerComponentParams = { ...e.headerComponentParams, disableSorting: true }
        } else {
          e.headerComponentParams.disableSorting = true
        }
      }
      if (e.field === CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE) {
        e.hide = true
      }
    })
    setGridOptions(gridOptions)
    gridApi.setColumnDefs(gridOptions.columnDefs)
    gridApi.redrawRows()
    setTimeout(() => gridApi.sizeColumnsToFit(), 0)
  }
}

export const setColumnSorting = (
  gridApi: GridApi | null,
  columDefs: ColDef[],
  disableSorting: boolean
) => {
  columDefs.forEach((e: ColDef) => {
    e.sortable = false
    if (
      e.headerComponentParams &&
      !unsortableColumns.includes(e.field as CLEANLAB_FRONTEND_COLUMN)
    ) {
      if (e.headerComponentParams && e.headerComponentParams.disableSorting === undefined) {
        e.headerComponentParams = { ...e.headerComponentParams, disableSorting: disableSorting }
      } else {
        e.headerComponentParams.disableSorting = disableSorting
      }
    }
  })
  gridApi?.setColumnDefs(columDefs)
}

export const applyDataLabelingFilters = (dispatch: (value: FilterActionType) => void) => {
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, false),
    filterType: FilterOperator.Equals,
    filterInput: '-',
    agGridType: '',
    isOpen: false,
    isPreset: false,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter],
  })
}

export const applyThresholdFilter = (
  dispatch: (value: FilterActionType) => void,
  thresholdScore: number
) => {
  dispatch({
    type: CleansetFilterActions.APPLY_FILTER,
    filterData: {
      property: CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE,
      filterType: FilterOperator.LessThan,
      filterInput: thresholdScore,
      headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE, false),
      agGridType: 'agNumberColumnFilter',
      isOpen: false,
      isPreset: false,
    },
  })
}

const DEFAULT_SHOWN_COLUMNS = [
  'annotator',
  'annotatorname',
  'annotators',
  'annotatorsname',
  'datasource',
  'labelsource',
  'source',
  'sourcename',
] as const

export const showColumnByDefault = (colDef?: ColDef) => {
  return matchFieldName(colDef?.field, DEFAULT_SHOWN_COLUMNS)
}

export const getColumnStatus = (
  columnName: string,
  columnStatuses: { [columnName: string]: string }
): string => {
  if (!checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS')) {
    return ColumnStatus.READY
  }
  return columnStatuses[columnName] ?? ColumnStatus.READY
}

/* The two functions below are taken from:
  https://blog.ag-grid.com/wrapping-column-header-text/#custom-header-wrapping
*/
export const applyHeaderHeight = (gridApi: GridApi | null) => {
  const padding = 20
  const height = headerHeightGetter() + padding
  gridApi?.setHeaderHeight(height)
  return height
}

export const headerHeightGetter = () => {
  const columnHeaderTexts = [...document.querySelectorAll('.custom-header-tooltip')]
  const clientHeights = columnHeaderTexts.map((headerText) => headerText.clientHeight)

  const tallestHeaderTextHeight = Math.max(...clientHeights)
  return tallestHeaderTextHeight
}
