import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Button, FormLabel, Grid, GridItem, Input, Stack, Textarea } from '@chakra-ui/react'
import { AgilityApp } from '@services/agilityApps/constants'
import React, { useState } from 'react'

interface AgilityAppFormProps {
  initialData: Partial<AgilityApp>
  onSubmit: (data: AgilityApp) => void
  isUpdate: boolean
}

export const AgilityAppForm: React.FC<AgilityAppFormProps> = ({
  initialData,
  onSubmit,
  isUpdate,
}) => {
  const [formData, setFormData] = useState<AgilityApp>({
    ...initialData,
    suggested_questions: initialData.suggested_questions || [''],
  } as AgilityApp)
  const [jsonError, setJsonError] = useState<string | null>(null)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleJsonChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value
    setFormData((prev) => ({ ...prev, crawler_config: value }))

    try {
      JSON.parse(value)
      setJsonError(null)
    } catch (error) {
      setJsonError('Invalid JSON')
    }
  }

  const handleQuestionChange = (index: number, value: string) => {
    const newQuestions = [...formData.suggested_questions]
    newQuestions[index] = value
    setFormData((prev) => ({ ...prev, suggested_questions: newQuestions }))
  }

  const handleAddQuestion = () => {
    setFormData((prev) => ({
      ...prev,
      suggested_questions: [...prev.suggested_questions, ''],
    }))
  }

  const handleRemoveQuestion = (index: number) => {
    const newQuestions = formData.suggested_questions.filter((_, i) => i !== index)
    setFormData((prev) => ({
      ...prev,
      suggested_questions: newQuestions.length ? newQuestions : [''],
    }))
  }

  const handleSubmit = () => {
    onSubmit(formData)
  }

  const isSubmitDisabled =
    !formData.name || !formData.url_suffix || !formData.crawler_config || !formData.chat_title

  return (
    <Stack spacing="1.5rem">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <FormLabel>Name</FormLabel>
          <Input name="name" value={formData.name} onChange={handleInputChange} />
        </GridItem>
        <GridItem>
          <FormLabel>Description</FormLabel>
          <Input name="description" value={formData.description} onChange={handleInputChange} />
        </GridItem>
        <GridItem>
          <FormLabel>URL Suffix</FormLabel>
          <Input name="url_suffix" value={formData.url_suffix} onChange={handleInputChange} />
        </GridItem>
        <GridItem>
          <FormLabel>iframe URL</FormLabel>
          <Input name="iframe_url" value={formData.iframe_url} onChange={handleInputChange} />
        </GridItem>
        <GridItem>
          <FormLabel>Chat Title</FormLabel>
          <Input name="chat_title" value={formData.chat_title} onChange={handleInputChange} />
        </GridItem>
        <GridItem>
          <FormLabel>Chat Subtitle</FormLabel>
          <Input name="chat_subtitle" value={formData.chat_subtitle} onChange={handleInputChange} />
        </GridItem>
        <GridItem>
          <FormLabel>Crawler Config</FormLabel>
          <Textarea
            name="crawler_config"
            value={formData.crawler_config}
            onChange={handleJsonChange}
            placeholder="Enter JSON configuration"
            isDisabled={isUpdate}
          />
          {jsonError && <p style={{ color: 'red' }}>{jsonError}</p>}
        </GridItem>
        <GridItem colSpan={2}>
          <FormLabel>Suggested Questions</FormLabel>
          {formData.suggested_questions.map((question, index) => (
            <Grid key={index} templateColumns="1fr auto" gap={2} mb={2}>
              <Input
                value={question}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
                placeholder={`Question ${index + 1}`}
              />
              <Button onClick={() => handleRemoveQuestion(index)} colorScheme="red" size="sm">
                <DeleteIcon />
              </Button>
            </Grid>
          ))}
          <Button onClick={handleAddQuestion} leftIcon={<AddIcon />} size="sm" mt={2}>
            Add Question
          </Button>
        </GridItem>
      </Grid>
      <Button colorScheme="blue" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
        Submit
      </Button>
    </Stack>
  )
}
