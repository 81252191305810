import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { AgilityApp } from '@services/agilityApps/constants'
import React from 'react'

import { AgilityAppForm } from '../AgilityAppForm'

interface CreateAppModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (newApp: AgilityApp) => void
}

const CreateAppModal: React.FC<CreateAppModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const initialData: Partial<AgilityApp> = {
    name: '',
    description: '',
    url_suffix: '',
    crawler_config: '',
    iframe_url: '',
    chat_title: '',
    chat_subtitle: '',
    suggested_questions: [''],
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Agility App</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AgilityAppForm initialData={initialData} onSubmit={onSubmit} isUpdate={false} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateAppModal
