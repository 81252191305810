import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import useGridClassname from '@hooks/useGridClassname'
import { AgilityApp } from '@services/agilityApps/constants'
import {
  useCreateAgilityApp,
  useDeleteAgilityApp,
  useExportScrapedUrls,
  useUpdateAgilityApp,
} from '@services/agilityApps/mutations'
import { useAgilityApps } from '@services/agilityApps/queries'
import { downloadFileFromAxiosResponse } from '@utils/functions/downloadFile'
import { RowSelectedEvent } from 'ag-grid-community'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { AxiosResponse } from 'axios'
import { useRef, useState } from 'react'

import ConfirmDeleteDialog from '../confirmDeleteDialog/ConfirmDeleteDialog'
import { agilityGridOptions } from './AgilityApps.helpers'
import CreateAppModal from './createAgilityAppModal/CreateAppModal'
import { UpdateAppModal } from './updateAgilityAppModal/UpdateAppModal'

const AgilityApps = () => {
  const numRowsPerPage = 20
  const toast = useToast()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [selectedRowData, setSelectedRowData] = useState<AgilityApp | null>(null)
  const [generatedUrl, setGeneratedUrl] = useState<string>('')
  const cancelDeleteRef = useRef(null)
  const {
    isOpen: isCreateAppOpen,
    onOpen: onCreateAppOpen,
    onClose: onCreateAppClose,
  } = useDisclosure()
  const {
    isOpen: isUpdateAppOpen,
    onOpen: onUpdateAppOpen,
    onClose: onUpdateAppClose,
  } = useDisclosure()

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: createAgilityApp } = useCreateAgilityApp()
  const { mutate: updateAgilityApp } = useUpdateAgilityApp()
  const { mutate: deleteAgilityApp } = useDeleteAgilityApp()

  const agilityApps = useAgilityApps()

  const onExportSuccess = (res: AxiosResponse) => {
    downloadFileFromAxiosResponse(res)
  }

  const { mutate: exportScrapedUrls, isLoading: isExportLoading } =
    useExportScrapedUrls(onExportSuccess)

  const handleCreateAppSubmit = (agilityApp: AgilityApp) => {
    createAgilityApp(agilityApp)
    onCreateAppClose()
  }

  const handleUpdateAppSubmit = (updatedApp: AgilityApp) => {
    updateAgilityApp(updatedApp)
    onUpdateAppClose()
    clearSelection()
  }

  const handleDeleteAppSubmit = (appId: string | undefined) => {
    if (appId === undefined) {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Invalid app ID',
          status: 'info',
        }),
      })
    } else {
      deleteAgilityApp(appId, {
        onSuccess: () => {
          gridApi?.refreshCells()
          onDeleteClose()
          setSelectedRowData(null) // Clear selected row data after deletion
        },
      })
    }
  }

  const handleRowSelected = (event: RowSelectedEvent) => {
    if (event.node.isSelected()) {
      const selectedData = event.data
      setSelectedRowData({
        id: selectedData.id,
        name: selectedData.name,
        description: selectedData.description,
        url_suffix: selectedData.urlSuffix,
        crawler_config: JSON.stringify(selectedData.crawlerConfig),
        iframe_url: selectedData.iframeUrl,
        chat_title: selectedData.chatTitle,
        chat_subtitle: selectedData.chatSubtitle,
        suggested_questions: selectedData.suggestedQuestions || [],
        status: selectedData.status,
      })
    } else {
      setSelectedRowData(null)
    }
  }

  const handleGenerateUrl = () => {
    if (selectedRowData?.url_suffix) {
      const url = `https://rag.app/${selectedRowData.url_suffix}`
      setGeneratedUrl(url)
      onOpen()
    } else {
      toast({
        title: 'No row selected',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const clearSelection = () => {
    if (gridApi) {
      gridApi.deselectAll()
    }
    setSelectedRowData(null)
  }

  // Add this function to handle grid ready event
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
  }

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(generatedUrl)
      .then(() => {
        toast({
          title: 'URL copied to clipboard',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
        toast({
          title: 'Failed to copy URL',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      })
  }

  return (
    <Stack>
      <Flex justify="flex-end">
        <HStack>
          <Button colorScheme="whatsapp" size="sm" onClick={onCreateAppOpen}>
            Create
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={onUpdateAppOpen}
            isDisabled={!selectedRowData || selectedRowData.status === 'IN_PROGRESS'}
          >
            Update
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (gridApi && gridApi?.getSelectedRows().length > 0) {
                onDeleteOpen()
              } else {
                toast({
                  ...defaultToastAlertProps,
                  render: renderChakraToastAlert({
                    heading: 'No apps selected',
                    status: 'info',
                  }),
                })
              }
            }}
          >
            Remove app
          </Button>
          <Button
            colorScheme="teal"
            size="sm"
            onClick={handleGenerateUrl}
            isDisabled={selectedRowData?.status !== 'READY'}
          >
            Generate Public-Facing URL
          </Button>
          <Button
            size="sm"
            onClick={() => {
              selectedRowData?.id && exportScrapedUrls(selectedRowData.id)
            }}
            isDisabled={
              !selectedRowData?.id || isExportLoading || selectedRowData.status !== 'READY'
            }
            isLoading={isExportLoading}
          >
            Export scraped URLs
          </Button>
        </HStack>
      </Flex>
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={agilityGridOptions}
          onGridReady={onGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={agilityApps}
          onRowSelected={handleRowSelected}
        />
      </Box>
      <CreateAppModal
        isOpen={isCreateAppOpen}
        onClose={onCreateAppClose}
        onSubmit={handleCreateAppSubmit}
      />
      {selectedRowData && (
        <UpdateAppModal
          isOpen={isUpdateAppOpen}
          onClose={() => {
            onUpdateAppClose()
            clearSelection()
          }}
          onSave={handleUpdateAppSubmit}
          app={selectedRowData}
        />
      )}
      {selectedRowData && gridApi && (
        <ConfirmDeleteDialog
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          cancelRef={cancelDeleteRef}
          onConfirm={() => handleDeleteAppSubmit(selectedRowData.id)}
          item="app"
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          clearSelection()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generated URL</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex>
              <Input value={generatedUrl} isReadOnly mr={2} />
              <Button onClick={handleCopyUrl} colorScheme="blue">
                Copy
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default AgilityApps
